/**
 * This file includes polyfills needed by Angular and is loaded before the app.
 * You can add your own extra polyfills to this file.
 *
 * This file is divided into 2 sections:
 *   1. Browser polyfills. These are applied before loading ZoneJS and are sorted by
 * browsers.
 *   2. Application imports. Files imported after ZoneJS that should be loaded before
 * your main file.
 *
 * The current setup is for so-called "evergreen" browsers; the last versions of
 * browsers that automatically update themselves. This includes Safari >= 10, Chrome
 * >= 55 (including Opera), Edge >= 13 on the desktop, and iOS 10 and Chrome on
 * mobile.
 *
 * Learn more in https://angular.io/guide/browser-support
 */

/***************************************************************************************************
 /**
 * By default, zone.js will patch all possible macroTask and DomEvents
 * user can disable parts of macroTask/DomEvents patch by setting following flags
 */

// (window as any).__Zone_disable_requestAnimationFrame = true;
// disable patch requestAnimationFrame
// (window as any).__Zone_disable_on_property = true; // disable patch onProperty
// such as onclick
// disable patch specified eventNames
(window as any).__zone_symbol__BLACK_LISTED_EVENTS = [
  'scroll',
  'touchmove',
  'mousemove',
];

import '@angular/localize/init';
/** Evergreen browsers require these. **/
import 'core-js/es/reflect';

/** ALL Firefox browsers require the following to support `@angular/animation`. **/
import 'web-animations-js/web-animations-next.min'; // Run `npm install --save web-animations-js`.
/***************************************************************************************************
 * Zone JS is required by default for Angular itself.
 */
import 'zone.js/dist/zone'; // Included with Angular CLI.
/*
 * in IE/Edge developer tools, the addEventListener will also be wrapped by zone.js
 * with the following flag, it will bypass `zone.js` patch for IE/Edge
 */
(window as any).__Zone_enable_cross_context_check = true;

/***************************************************************************************************
 * APPLICATION IMPORTS
 */

/**
 * Chrome 80 Array.reduce hotfix
 * https://bugs.chromium.org/p/chromium/issues/detail?id=1049982
 */
// eslint-disable-next-line prefer-arrow/prefer-arrow-functions
(function () {
  const arrayReduce = Array.prototype.reduce;
  let callback;
  Object.defineProperty(Array.prototype, 'reduce', {
    value: function (cb, ...args) {
      callback = cb;
      return arrayReduce.call(this, callback, ...args);
    },
  });
})();
